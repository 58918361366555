<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Vendors/View</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="content-body">
          <section class="page-user-profile">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="d-block d-sm-flex mb-2 align-items-center">
                      <div class="mr-2 position-relative">
                        <img :src="user.avatar" alt="users avatar"
                             class="users-avatar-shadow rounded-circle"
                             height="120" width="120">
                        <div class="position-absolute update-image-avatar d-flex align-items-center justify-content-center">
                          <i class='bx bxs-camera' data-toggle="modal" data-target="#updateAvatarModal"></i>
                        </div>
                      </div>
                      <div class="media-body ">
                        <h4 class="media-heading">{{ userFullName }}</h4>
                        <h6 v-if="user.role.name" class="">{{ `${user.role.type} / ${user.role.name}` }}</h6>
                        <h6 v-if="code" class="pb-1">
                          <span>Code: </span><span>{{ code }}</span>
                        </h6>
                        <button :disabled="!can('vendor-update')"
                                :title="can('vendor-update') ? (status === 'Active' ? 'Active | Click to Inactive' : 'Inactive | Click to Active') : 'Update Forbidden'"
                                @click="updateVendorStatus(status === 'Active' ? 0 : 1)"
                                :class="status === 'Active' ? 'badge-light-success' : 'badge-light-danger'"
                                class="badge">{{ status }}
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3 ">
                    <div class="card ">
                      <div class="card-body">
                        <div class="d-flex align-items-center mb-1">
                          <div>
                            <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">User
                              information</h5>
                          </div>
                          <div class="ml-1" style="cursor: pointer;">
                            <button :title="can('vendor-update') ? 'Update' : 'Update Forbidden'"
                                    :disabled="!can('vendor-update')"
                                    class="btn single-edit-btn text-primary"
                                    data-toggle="modal"
                                    data-target="#userDataUpdateModal" >
                              <i class='bx bx-edit-alt'></i>
                            </button>
                          </div>
                        </div>
                        <ul class="list-unstyled">
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">First Name: </span>
                            <span>{{ user.firstName }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">Last Name: </span>
                            <span>{{ user.lastName }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Phone Number: </span>
                            <span :class="user.phoneNumber ? '' : 'text-danger'">{{ user.phoneNumber ? user.phoneNumber : 'Not Provided' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Email: </span>
                            <span :class="user.email ? '' : 'text-danger'">{{ user.email ? user.email : 'Not Provided' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Date Of Birth: </span>
                            <span :class="userDateOfBirth !== 'Not Provided' ? '' : 'text-danger'">{{ userDateOfBirth }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Created at: </span>
                            <span>{{ createdAt }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9 mb-4">
                    <div class="card">
                      <div class="card-body">
                        <div>
                          <div class="d-flex align-items-center mb-1">
                            <div>
                              <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">Role Information</h5>
                            </div>
                            <div class="ml-1" style="cursor: pointer;">
                              <button :title="can('vendor-update') ? 'Update' : 'Update Forbidden'"
                                      :disabled="!can('vendor-update')"
                                      class="btn single-edit-btn text-primary"
                                      data-toggle="modal"
                                      data-target="#userRoleDataUpdateModal" >
                                <i class='bx bx-edit-alt'></i>
                              </button>
                            </div>
                          </div>
                          <div v-if="user.role.name" class="row">
                            <div class="col-sm-6 col-12">
                              <h6><small class="text-muted pb-0">TYPE:</small></h6>
                              <p>{{ user.role.type }}</p>
                            </div>
                            <div class="col-sm-6 col-12 mt-1 mt-md-0">
                              <h6><small class="text-muted">NAME:</small></h6>
                              <p>{{ user.role.name }}</p>
                            </div>
                          </div>
                          <div v-else>
                            <p>No Role Assigned</p>
                          </div>
                        </div>
                        <hr class="my-2">
                        <div>
                          <div class="d-flex align-items-center mb-1">
                            <div>
                              <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">Address
                                Information</h5>
                            </div>
                            <div class="ml-1" style="cursor: pointer;">
                              <button :title="can('vendor-update') ? 'Update' : 'Update Forbidden'"
                                      :disabled="!can('vendor-update')"
                                      class="btn single-edit-btn text-primary"
                                      data-toggle="modal"
                                      data-target="#addressUpdateModal" >
                                <i class='bx bx-edit-alt'></i>
                              </button>
                            </div>
                          </div>
                          <div v-if="address.street" class="row">
                            <div class="col-sm-12 col-12">
                              <h6><small class="text-muted">CURRENT ADDRESS: </small></h6>
                              <p>{{ userFullAddress }}</p>
                            </div>
                          </div>
                          <div v-else>
                            <p>No Address Assigned</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <UserUpdateModal modal-name="userDataUpdateModal" :modal-usages-for="1" :user-id="user.id"
                           :user-data="userData" @updatedUserData="updatedUserData"/>
          <UserRoleUpdateModal modal-name="userRoleDataUpdateModal" :modal-usages-for="2" :user-id="user.id"
                               :get-roles-params="getRolesParams" :selected-role-data="selectedRoleData"
                               @updatedUserRoleData="updatedUserRoleData"/>
          <AddressUpdateModal modal-name="addressUpdateModal" address-update-for-model-name="vendor" :model-id="id"
                              :user-id="user.id" :selected-address-data="selectedAddressData"
                              :business-status="true"
                              @updateModalAddressData="updateModalAddressData"/>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout'
import UserUpdateModal from "@/components/backEnd/modal/UserUpdateModal";
import UserRoleUpdateModal from "@/components/backEnd/modal/UserUpdateModal";
import AddressUpdateModal from "@/components/backEnd/modal/AddressUpdateModal";

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {mapActions, mapGetters} from "vuex";

export default {
  name: "VendorView",

  mixins: [ShowToastMessage, Loader, Authorization],

  components: {
    AppLayout,
    UserUpdateModal,
    UserRoleUpdateModal,
    AddressUpdateModal,
  },
  data() {
    return {
      id: '',
      code: '',
      status: '',
      createdAt: '',
      user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        avatar: '/app-assets/defaults/images/users/avatar.svg',
        role: {
          id: '',
          type: '',
          name: '',
          description: '',
        },
      },
      address: {
        id: '',
        street: '',
        suburb: '',
        state: '',
        postCode: '',
        country: '',
        business: {
          id: '',
          name: '',
        },
      },
      getVendorParams: {
        with_relation: ['user', 'user.role', 'user.media', 'address.business']
      },

      getRolesParams: {
        type: 6 // vendor role's type
      }
    }

  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      vendor: 'appVendors/vendor'
    }),

    userFullName() {
      return `${this.user.firstName} ${this.user.lastName}`;
    },
    userFullAddress() {
      let businessId = this.address.business.id;
      let businessName = this.address.business.name;
      let addressStreet = this.address.street;
      let addressSuburb = this.address.suburb;
      let addressState = this.address.state.toUpperCase();
      let addressPostCode = this.address.postCode;
      let addressCountry = this.address.country;
      return businessId ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },
    userDateOfBirth() {
      let userDateOfBirth = this.user.dateOfBirth ? new Date(this.user.dateOfBirth) : null;
      return userDateOfBirth ? userDateOfBirth.toLocaleDateString("en-AU", {
        day: "numeric",
        month: "long",
        year: "numeric"
      }) : 'Not Provided';
    },
    userData() {
      return {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        phoneNumber: this.user.phoneNumber,
        dateOfBirth: this.user.dateOfBirth,
      }
    },

    addressData() {
      return {
        street: this.address.street,
        suburb: this.address.suburb,
        state: this.address.state,
        postCode: this.address.postCode,
        country: this.address.country,
      }
    },

    selectedAddressData() {
      return {
        value: this.address.id,
        name: this.userFullAddress,
      };
    },

    selectedRoleData() {
      return {
        value: this.user.role.id,
        name: this.user.role.name,
      }
    },
  },
  methods: {
    ...mapActions({
      resetVendor: 'appVendors/resetVendor',
      getVendor: 'appVendors/getVendor',
      putVendor: 'appVendors/putVendor',
      commitNotFoundRouteStatus: 'commitNotFoundRouteStatus',
    }),
    async getSingleVendor() {
      let paramObj = {
        id: this.$route.params.id,
        params: this.getVendorParams,
      };

      await this.getVendor(paramObj).then(async (response) => {

        if (response.status === 404) {
          this.commitNotFoundRouteStatus(true);
          return;
        }

        if (response.status === 200) {
          this.id = this.vendor.id ? this.vendor.id : '';
          this.code = this.vendor.code ? this.vendor.code : '';
          this.status = this.vendor.status ? this.vendor.status : '';
          this.createdAt = this.vendor.created_at ? this.vendor.created_at : '';

          this.user.id = this.vendor.user && this.vendor.user.id ? this.vendor.user.id : '';
          this.user.firstName = this.vendor.user && this.vendor.user.first_name ? this.vendor.user.first_name : '';
          this.user.lastName = this.vendor.user && this.vendor.user.last_name ? this.vendor.user.last_name : '';
          this.user.email = this.vendor.user && this.vendor.user.email ? this.vendor.user.email : '';
          this.user.phoneNumber = this.vendor.user && this.vendor.user.phone_number ? this.vendor.user.phone_number : '';
          this.user.dateOfBirth = this.vendor.user && this.vendor.user.date_of_birth ? this.vendor.user.date_of_birth : '';

          this.user.avatar = this.vendor.user && this.vendor.user.avatar ? this.vendor.user.avatar : '';

          this.user.role.id = this.vendor.user && this.vendor.user.role && this.vendor.user.role.id ? this.vendor.user.role.id : '';
          this.user.role.type = this.vendor.user && this.vendor.user.role && this.vendor.user.role.type ? this.vendor.user.role.type : '';
          this.user.role.name = this.vendor.user && this.vendor.user.role && this.vendor.user.role.name ? this.vendor.user.role.name : '';
          this.user.role.description = this.vendor.user && this.vendor.user.role && this.vendor.user.role.description ? this.vendor.user.role.description : '';

          this.address.id = this.vendor.address && this.vendor.address.id ? this.vendor.address.id : '';
          this.address.street = this.vendor.address && this.vendor.address.street ? this.vendor.address.street : '';
          this.address.suburb = this.vendor.address && this.vendor.address.suburb ? this.vendor.address.suburb : '';
          this.address.state = this.vendor.address && this.vendor.address.state ? this.vendor.address.state : '';
          this.address.postCode = this.vendor.address && this.vendor.address.post_code ? this.vendor.address.post_code : '';
          this.address.country = this.vendor.address && this.vendor.address.country ? this.vendor.address.country : '';

          this.address.business.id = this.vendor.address && this.vendor.address.business && this.vendor.address.business.id ? this.vendor.address.business.id : '';
          this.address.business.name = this.vendor.address && this.vendor.address.business && this.vendor.address.business.name ? this.vendor.address.business.name : '';
        }

        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });

    },

    async updatedUserData(updatedUserData) {
      this.user.firstName = updatedUserData.userData.firstName;
      this.user.lastName = updatedUserData.userData.lastName;
      this.user.email = updatedUserData.userData.email;
      this.user.phoneNumber = updatedUserData.userData.phoneNumber;
      this.user.dateOfBirth = updatedUserData.userData.dateOfBirth;
    },

    async updatedUserRoleData(updatedUserRoleData) {
      this.user.role.id = updatedUserRoleData.userRoleData.id;
      this.user.role.type = updatedUserRoleData.userRoleData.type;
      this.user.role.name = updatedUserRoleData.userRoleData.name;
      this.user.role.description = updatedUserRoleData.userRoleData.description;
    },

    async updateModalAddressData(updateModalAddressData) {
      console.log(updateModalAddressData)
      this.address.id = updateModalAddressData.addressData.id;
      this.address.street = updateModalAddressData.addressData.street;
      this.address.suburb = updateModalAddressData.addressData.suburb;
      this.address.state = updateModalAddressData.addressData.state;
      this.address.postCode = updateModalAddressData.addressData.postCode;
      this.address.country = updateModalAddressData.addressData.country;

      this.address.business.id = updateModalAddressData.addressData.business.id;
      this.address.business.name = updateModalAddressData.addressData.business.name;
    },

    async updateVendorStatus(status) {
      let dataObj = {
        id: this.id,
        data: {
          status: status,
        },
      };
      this.putVendor(dataObj).then(async (response) => {
        if (response.status === 200) {
          this.status = this.vendor.status;
          let toastObj = {message: 'Info has Updated', type: 'success'};
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    }

  },
  async mounted() {
    this.loader(true);
    await this.getSingleVendor();
    this.loader(false);
  },
  async beforeUnmount() {
    await this.resetVendor();
  },
}
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.update-image-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #5a8dee;
  border: 2px solid white;
  top: 79%;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.update-image-avatar:hover {
  background-color: #4173ce;
}

.update-image-avatar > i {
  font-size: 20px;
  z-index: 10;
  color: white;
}

@media only screen and (max-width: 576px) {
  .update-image-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #5a8dee;
    border: 2px solid white;
    top: 64%;
    left: 92px;
    right: 0;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}
</style>
